import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import "./BasicModel.css";
import { CZKCurrency, formattedDate, formattedDateTimeLocalDisplay } from "../../utils/helper";

function BasicModal({ isOpen, handleClose, data, onUpdate }) {
    const { register, handleSubmit, setValue } = useForm();

    const handleUpdate = (formData) => {
        // Assume some update logic here
        const updatedData = {
            cottage_reservation_id: data.cottageReservationId, // identificator
            was_deposit_paid: formData.was_deposit_paid, // Formdata
            was_paid_full_amount: formData.was_paid_full_amount, // Formdata
        };

        // Call the onUpdate callback to send the updated data to the parent
        onUpdate(updatedData);
    };

    const handleDeleteReservation = () => {
        onUpdate({
            cottage_reservation_id: data.cottageReservationId,
            status: "delete",
        });
    };

    useEffect(() => {
        if (data) {
            setValue("was_deposit_paid", data.wasDepositPaid);
            setValue("was_paid_full_amount", data.wasPaidFullAmount);
        }
    }, [data, isOpen]);

    if (!isOpen) return null;
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title> {data?.title ?? "Bohužel hledaný výraz neexistuje"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
                {data ? (
                    <form className="d-flex flex-column w-100">
                        <div className="w-100">
                            <h6>
                                Stav Rezervace <strong>záloha</strong>:{" "}
                                <span>
                                    {data.wasDepositPaid ? (
                                        <p className="text-warning">Zaplacená záloha</p>
                                    ) : (
                                        <p className="text-danger">Nezaplacená záloha</p>
                                    )}
                                </span>
                            </h6>
                            <h6>
                                Stav Rezervace <strong>celá částka</strong>:{" "}
                                <span>
                                    {data.wasPaidFullAmount ? (
                                        <p className="text-success">Zaplacená celá částka</p>
                                    ) : (
                                        <p className="text-danger">Nezaplacená celá čáska</p>
                                    )}
                                </span>
                            </h6>
                            <div className="form-group w-100">
                                <label>Jméno uživatele</label>
                                <div className="form-control">{data.firstname}</div>
                            </div>
                            <div className="form-group w-100">
                                <label>Příjmení uživatele</label>
                                <div className="form-control">{data.lastname}</div>
                            </div>

                            <div className="form-group w-100">
                                <label>Email</label>
                                <div className="form-control">{data.email}</div>
                            </div>

                            <div className="form-group w-100">
                                <label>ID rezervace pro chatu</label>
                                <div className="form-control">{data.cottageReservationId}</div>
                            </div>
                            <div className="form-group w-100">
                                <label>Začátek Rezervace</label>
                                <div className="form-control">{formattedDateTimeLocalDisplay(data.start)}</div>
                            </div>
                            <div className="form-group w-100">
                                <label>Konec Rezervace</label>
                                <div className="form-control">{formattedDateTimeLocalDisplay(data.end)}</div>
                            </div>
                            <div className="form-group w-100">
                                <label>Cena za online zálohu</label>
                                <div className="form-control">{CZKCurrency.format(data.priceForCottageOverAll / 2)}</div>
                            </div>
                            <div className="form-group w-100">
                                <label>Celková cena</label>
                                <div className="form-control">{CZKCurrency.format(data.priceForCottageOverAll)}</div>
                            </div>
                            <label>Změnit stav zálohy</label>
                            <input type="checkbox" {...register("was_deposit_paid")} className={`form-control no-paid`} />
                            <label>Změnit stav, částka byla uhrazená celá </label>
                            <input type="checkbox" {...register("was_paid_full_amount")} className={`form-control no-paid`} />
                            <div className="items-container d-flex w-100 flex-column">
                                {data.cottageItems.map((item, index) => (
                                    <div className="item" key={index}>
                                        <h5>{item.UserItemReservation.CottageItem.Item.na}</h5>
                                        <div className="form-group w-100 d-flex align-items-center justify-content-around flex-wrap">
                                            <div className="form-group" style={{ width: "45%" }}>
                                                <label>Od</label>
                                                <div className="form-control w-100">
                                                    <p> {formattedDate(item.UserItemReservation.start_date)}</p>
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ width: "45%" }}>
                                                <label>Do</label>
                                                <div className="form-control">
                                                    <p> {formattedDate(item.UserItemReservation.end_date)}</p>
                                                </div>
                                            </div>
                                            <div className="form-group " style={{ width: "45%" }}>
                                                <label>Množství</label>
                                                <div className="form-control w-100">
                                                    <p> {item.UserItemReservation.amount}</p>
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ width: "45%" }}>
                                                <label>Cena Celkem</label>
                                                <div className="form-control w-100">
                                                    <p>{CZKCurrency.format(item.price_overall)} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>
                ) : (
                    <p>Žádná data k zobrazení</p>
                )}
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
                {data ? (
                    <>
                        <div className="d-flexd">
                            <Button type={"submit"} variant={"danger"} onClick={handleSubmit(handleDeleteReservation)}>
                                Smazat Rezervaci
                            </Button>
                        </div>
                        <div className="d-flex">
                            <Button type="submit" onClick={handleSubmit(handleUpdate)} variant={"success"} className="mx-1">
                                {" "}
                                Uložit změny{" "}
                            </Button>
                            <Button variant={"primary"} onClick={handleClose}>
                                Zavřít
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="d-flex">
                        <Button variant={"primary"} onClick={handleClose}>
                            Zavřít
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default BasicModal;
