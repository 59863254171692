import React from "react";
import "./InterestingFactsPage.css";
import { FaBiking, FaHiking, FaTree, FaWater, FaSlidersH } from "react-icons/fa";
import "../PageStyle.css";
import ReactReadMoreReadLess from "react-read-more-read-less";

function InterestingFactsPage(props) {
    return (
        <div className="container front-page interesting-facts-page" id="interesting-facts-page">
            <div className="introduction">
                <h1>Zajímavosti v okolí </h1>
                <ReactReadMoreReadLess
                    charLimit={300}
                    readMoreText={"Číst více"}
                    readLessText={"Číst méně"}
                    readMoreStyle={{ color: "black", cursor: "pointer", fontWeight: "bold" }}
                    readLessStyle={{ color: "black", cursor: "pointer", fontWeight: "bold" }}
                >
                    Naše 100-letá roubenka se nachází přímo v srdci Komorní Lhotky Moravskoslezských Beskyd. Tuto roubenku jsme původně koupili pro
                    nejkvalitnější ovzduší v celém okolí k pobytu s našimi dětmi. Okolní příroda přímo pobízí k celoroční odpočinkové ,ale taky
                    aktivní dovolené. Chata se nachází v těsné blízkosti říčky Ráztoky s výhledem na okolní kopce. U roubenky začíná turistická trasa
                    na Godulu, ale cyklotras a jiných turistických stezek tady máme dostatek, jako například Kyčera nebo Prašivá. V obci najdete
                    krásné udržované dětské hřiště s přilehlým rybníčkem a procházkou kolem statku se zvířátky se zase vrátíte k nám. A pro dospělé a
                    jejich odpočinek jsou v naší blízkosti Bylinné lázničky. K aktivní dovolené Vám rádi za poplatek zapůjčíme elektrokola nebo
                    terénní čtyřkolku. A pokud se Vám bude chtít jen odpočívat na naší zahradě, tak jistě oceníte prostorné posezení s kvalitním
                    grilem a vaše děti dětský domeček se skluzavkou a houpačkami. Budeme se těšit na Vaši návštěvu.
                </ReactReadMoreReadLess>
            </div>
            <div className="pictures d-flex justify-content-center m-5">
                <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={
                        "https://www.google.com/maps/place/739+53+Komorn%C3%AD+Lhotka-Hnojn%C3%ADk/@49.6441042,18.4832699,13z/data=!3m1!4b1!4m6!3m5!1s0x471408decc21df39:0x400af0f66153b70!8m2!3d49.658104!4d18.5277819!16s%2Fm%2F026x0h6?entry=ttu"
                    }
                >
                    <img src="https://www.komorni-lhotka.cz/image.php?nid=18051&oid=7719848" alt={"komorni-lhotka"} />
                </a>
            </div>
            <div className="actions">
                <div className="icon icon1">
                    <div className="icon-detail">
                        <FaBiking />
                    </div>
                    <h5>Cyklo Trasy</h5>
                    <p>
                        Pro více info klikni{" "}
                        <a
                            href="http://cyklotrasy.cz/encyklopedie/objekty1.phtml?id=11151"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "green", textDecoration: "underline", opacity: 1, fontWeight: "bold" }}
                        >
                            {" "}
                            ZDE{" "}
                        </a>
                    </p>
                </div>
                <div className="icon icon12">
                    <div className="icon-detail">
                        <FaHiking />
                    </div>
                    <h5>Turista</h5>
                    <p>
                        Pro více info klikni{" "}
                        <a
                            href="https://www.turistika.cz/trasy/okoli-komorni-lhotky/detail"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "green", textDecoration: "underline", opacity: 1, fontWeight: "bold" }}
                        >
                            {" "}
                            ZDE{" "}
                        </a>
                    </p>
                </div>
                <div className="icon icon3">
                    <div className="icon-detail">
                        <FaTree />
                    </div>
                    <h5>Tipy na výlet </h5>
                    <p>
                        Pro více info klikni{" "}
                        <a
                            href="https://www.navylet.cz/cs/atrakce/komorni-lhotka"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "green", textDecoration: "underline", opacity: 1, fontWeight: "bold" }}
                        >
                            {" "}
                            ZDE{" "}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default InterestingFactsPage;
