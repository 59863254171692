import React, { useState } from "react";
import "./GalleryPage.css";
import "../PageStyle.css";
import Data from "./Data";

import { Modal } from "react-bootstrap";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import "swiper/css";
import "swiper/css/navigation";
import { FaXmark } from "react-icons/fa6";

function GalleryPage(props) {
    const [images, setImages] = useState(Data);
    const [categories, setCategories] = useState(["VŠE", ...new Set(images.map((image) => image.category))]);
    const [clickedImage, setClickedImage] = useState(null);

    const displaySeasonImages = (category) => {
        const filteredImages = Data.filter((image) => (image.category === category ? image : null));
        if (filteredImages.length === 0) return setImages(Data); // this is for ALL images
        setImages(filteredImages);
    };

    const getImageByPosition = (clickedImagePosition) => {
        if (clickedImagePosition < 0) {
            setClickedImage(images.length - 1);
            return images[0].image;
        }
        if (clickedImagePosition >= images.length) {
            setClickedImage(0);
            return images[images.length - 1].image;
        }
        return images[clickedImagePosition].image;
    };

    return (
        <div className="container front-page gallery-page d-flex flex-column w-100 justify-content-center align-content-center" id={"gallery-page"}>
            <h1>FotoGalerie</h1>
            <div className={"d-flex justify-content-center p-3"}>
                {categories.map((category, index) => (
                    <button className="category-button" key={index} onClick={() => displaySeasonImages(category)}>
                        {category}
                    </button>
                ))}
            </div>
            <div className="grid-wrapper">
                {images.map((image, index) => (
                    <div key={index} className={"blur-load"}>
                        <img alt="image" loading={"lazy"} src={image.image} onClick={() => setClickedImage(index)} />
                    </div>
                ))}
            </div>

            {clickedImage !== null && (
                <div class={"container"}>
                    {/* Modal */}
                    <Modal show={true} onHide={() => setClickedImage(null)} centered size="xl" dialogClassName="">
                        <Modal.Header
                            className={"p-0 m-0 w-100 flex justify-content-end py-2 px-3"}
                            style={{ cursor: "pointer" }}
                            onClick={() => setClickedImage(null)}
                        >
                            <FaXmark size={20} className={"p-10"} />
                        </Modal.Header>
                        <Modal.Body
                            className="p-2"
                            style={{
                                width: "100%",
                                height: "80%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {
                                <div className="d-flex justify-content-center align-items-center">
                                    <div
                                        onClick={() => setClickedImage((prev) => prev - 1)}
                                        style={{ cursor: "pointer", color: "lightgray" }}
                                        className={"p-10 mx-1"}
                                    >
                                        <FaChevronLeft size={40} />
                                    </div>

                                    <img
                                        src={getImageByPosition(clickedImage)}
                                        alt={`Nature ${+1}`}
                                        className="img-fluid"
                                        style={{ objectFit: "cover", width: "85%", height: "100%" }}
                                    />
                                    <div
                                        onClick={() => setClickedImage((prev) => prev + 1)}
                                        style={{ cursor: "pointer", color: "lightgray" }}
                                        className={"p-10 mx-1"}
                                    >
                                        <FaChevronRight size={40} />
                                    </div>
                                </div>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}

export default GalleryPage;
