const data = [
    // Exterior (LÉTO) Images
    {
        id: 1,
        image: "/cottageImages/Exterier/Exterier 01 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 2,
        image: "/cottageImages/Exterier/Exterier 02 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 3,
        image: "/cottageImages/Exterier/Exterier 03 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 4,
        image: "/cottageImages/Exterier/Exterier 04 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 5,
        image: "/cottageImages/Exterier/Exterier 05 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 6,
        image: "/cottageImages/Exterier/Exterier 06 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 7,
        image: "/cottageImages/Exterier/Exterier 07 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 8,
        image: "/cottageImages/Exterier/Exterier 08 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 9,
        image: "/cottageImages/Exterier/Exterier 09 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 10,
        image: "/cottageImages/Exterier/Exterier 10 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 11,
        image: "/cottageImages/Exterier/Exterier 11 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 12,
        image: "/cottageImages/Exterier/Exterier 12 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 13,
        image: "/cottageImages/Exterier/Exterier 13 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 14,
        image: "/cottageImages/Exterier/Exterier 14 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 15,
        image: "/cottageImages/Exterier/Exterier 15 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 16,
        image: "/cottageImages/Exterier/Exterier 16 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 17,
        image: "/cottageImages/Exterier/Exterier 17 Medium.jpeg",
        category: "LÉTO",
    },
    {
        id: 18,
        image: "/cottageImages/Exterier/Exterier 18 Medium.jpeg",
        category: "LÉTO",
    },
    // Interior (INTERIÉR) Images
    {
        id: 19,
        image: "/cottageImages/Interier/Interier 01 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 20,
        image: "/cottageImages/Interier/Interier 02 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 21,
        image: "/cottageImages/Interier/Interier 03 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 22,
        image: "/cottageImages/Interier/Interier 04 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 23,
        image: "/cottageImages/Interier/Interier 05 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 24,
        image: "/cottageImages/Interier/Interier 06 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 25,
        image: "/cottageImages/Interier/Interier 07 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 26,
        image: "/cottageImages/Interier/Interier 08 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 27,
        image: "/cottageImages/Interier/Interier 09 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 28,
        image: "/cottageImages/Interier/Interier 10 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 29,
        image: "/cottageImages/Interier/Interier 11 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 30,
        image: "/cottageImages/Interier/Interier 12 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 31,
        image: "/cottageImages/Interier/Interier 13 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 32,
        image: "/cottageImages/Interier/Interier 14 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 33,
        image: "/cottageImages/Interier/Interier 15 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 34,
        image: "/cottageImages/Interier/Interier 16 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 35,
        image: "/cottageImages/Interier/Interier 17 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 36,
        image: "/cottageImages/Interier/Interier 18 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 37,
        image: "/cottageImages/Interier/Interier 19 Medium.jpeg",
        category: "INTERIÉR",
    },
    {
        id: 38,
        image: "/cottageImages/Interier/Interier 20 Medium.jpeg",
        category: "INTERIÉR",
    },

    // WINTER IMAGES
    {
        id: 39,
        image: "/cottageImages/Winter/Zima 01 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 40,
        image: "/cottageImages/Winter/Zima 02 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 41,
        image: "/cottageImages/Winter/Zima 03 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 42,
        image: "/cottageImages/Winter/Zima 04 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 43,
        image: "/cottageImages/Winter/Zima 05 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 44,
        image: "/cottageImages/Winter/Zima 06 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 45,
        image: "/cottageImages/Winter/Zima 07 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 45,
        image: "/cottageImages/Winter/Zima 08 Medium.jpeg",
        category: "ZIMA",
    },
    {
        id: 45,
        image: "/cottageImages/Winter/Zima 09 Medium.jpeg",
        category: "ZIMA",
    },
];

export default data;
